body {
  margin: 20px;
}

.canvasRow:after {
  clear: both;
  content: '';
  display: table;
}

.canvasButton {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.colorButton {
  background: #fff;
  border: 1px solid #999;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin: 2px;
  padding: 0;
  width: 34px;
}

.canvasButton:focus {
  outline: none;
}

.app {
  display: flex;
  flex-direction: row;
}

.toolbar {
  margin-left: 20px;
  width: 190px;
}

.currColor {
  width: 100%;
  margin-bottom: 20px;
}
.currColor:before {
  content: '';
  display: block;
  padding-top: 100%;
}

footer {
  margin-top: 2em;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 0.9em;
}
